import * as React from "react";
// import Reaptcha from "reaptcha";
import classnames from "classnames";
import { compose } from "recompose";
import { StaticImage } from "gatsby-plugin-image";
import useFbq from "../../hooks/useFbq";
import { v4 } from "uuid";
import { CardElement, Elements, injectStripe } from "react-stripe-elements";
import { Link, navigate } from "gatsby";
import { addDays, format } from "date-fns";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { PRICING, PRICES } from "../../constants/pricing";
import { StripeWrapper } from "../../components/Stripe/StripeWrapper";
import useMobileAppCheck from "../../hooks/useMobileAppCheck";

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

function moneyCalculation(amount, discount) {
  const discountToTakeOff = Number(amount * (discount / 100)).toFixed(2);
  const result = amount - discountToTakeOff;
  const resultTruncated = result.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

  return resultTruncated;
}
const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        fontFamily: "Poppins, Helvetica",
        "::placeholder": {
          color: "#aab7c4",
        },
        padding,
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };
};

const STEPS = {
  NAME_EMAIL: 0,
  PAYMENT: 1,
};

const initialState = {
  loading: false,
  step: STEPS.NAME_EMAIL,
  contactId: null,
  payment: null,
  error: null,
  //form
  username: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  agreeWaiver: false,
  agreeTerms: false,
  purchaseBump: false,
  subscriptionType: "8",
  // promo code stuff
  promoCodeInvalid: null,
  selectedPromoCode: null,
  promoCode: "",
  isCheckingPromoCode: false,
  verified: false,
};

function BaseSignUpForm({ fontSize, firebase, stripe, fbq }) {
  const [state, setState] = React.useState(initialState);
  const isInApp = useMobileAppCheck();

  const handleOnChange = (event) => {
    const newState = {
      [event.target.name]: event.target.value,
    };
    if (event.target.name === "subscriptionType") {
      // reset the promo code if someone changed the subscription type
      newState.selectedPromoCode = null;
      newState.promoCodeInvalid = null;
      newState.promoCode = "";
    }

    setState({ ...state, ...newState });
  };

  const onChangeCheckbox = (event) =>
    setState({ ...state, [event.target.name]: event.target.checked });

  const onPaymentChange = (event) => setState({ ...state, payment: event });

  const emailPattern = /.+@.+\..+/;
  const emailInvalid = emailPattern.test(state.email) === false;
  const passwordsDoNotMatch = state.passwordOne !== state.passwordTwo;

  const nameEmailInvalid =
    passwordsDoNotMatch ||
    state.passwordOne === "" ||
    state.username === "" ||
    emailInvalid;

  const paymentInvalid =
    !state.payment ||
    state.payment.complete === false ||
    state.agreeWaiver === false ||
    state.agreeTerms === false;

  const handleNameEmailSubmit = async () => {
    try {
      setState({ ...state, loading: true });

      const addContactResult = await fetch(
        "/.netlify/functions/ac-create-or-get-contact",
        {
          method: "POST",
          body: JSON.stringify({
            email: state.email,
            username: state.username,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      const contactJson = await addContactResult.json();
      if (contactJson.status === "succeeded") {
        setState({
          ...state,
          contactId: contactJson.contactId,
          step: STEPS.PAYMENT,
          loading: false,
        });
      } else {
        // do error case
        console.log("failed API call");
      }
    } catch (err) {
      // do error case
      console.error(err);
    }
  };

  const handlePaymentSubmit = async () => {
    try {
      setState({ ...state, loading: true });

      // check to see if entered a promo code but didn't apply it
      if (state.promoCode && state.promoCodeInvalid === null) {
        setState({
          ...state,
          loading: false,
          error: {
            message: "You've entered a promo code, but haven't applied it.",
          },
        });

        // jump out
        return;
      }

      // promo code is not entered or valid, continue on
      firebase
        .doCreateUserWithEmailAndPassword(state.email, state.passwordOne)
        .then((authUser) => {
          return firebase
            .user(authUser.user.uid)
            .set(
              {
                username: state.username,
                email: state.email,
                roles: {},
              },
              { merge: true }
            )
            .then(() => {
              stripe.createToken().then((payload) => {
                const customer = JSON.stringify({
                  token: payload.token,
                  name: state.username,
                  email: state.email,
                  phone: "",
                  uid: authUser.user.uid,
                  // freeWeek: this.props.freeWeek,
                  promoCode: state.promoCode,
                  idempotency_key: v4(),
                  contactId: state.contactId,
                  purchaseBumpNutrition: state.purchaseBump,
                  url: `https://${window.location.host}`,
                });
                fetch("/.netlify/functions/create-customer", {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: customer,
                })
                  .then((res) => res.json())
                  .then(async (data) => {
                    if (data.status === "succeeded") {
                      fbq("Subscribe", {
                        currency: "USD",
                        content_name: "1 month membership",
                        value: PRICES[Number(state.subscriptionType)],
                      });

                      const { presale, stripe_id, created_on } = data;
                      firebase
                        .user(authUser.user.uid)
                        .set(
                          {
                            presale,
                            stripe_id,
                            created_on,
                            phone: "",
                            promoCode: state.promoCode,
                            signupReason: "",
                            reasonMember: null,
                            instagram: "",
                            subscription_status: "ready",
                            subscriptionType: Number(state.subscriptionType),
                          },
                          { merge: true }
                        )
                        .then(async () => {
                          navigate(ROUTES.GETTING_STARTED);
                        });
                    }
                  });
              });
            });
        })
        //
        .catch((error) => {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }

          setState({ ...state, error, loading: false });
        });
    } catch (err) {
      console.error(err);
    }
  };

  const checkPromoCode = async () => {
    setState({ ...state, isCheckingPromoCode: true });

    try {
      if (state.promoCode !== "") {
        const response = await fetch("/.netlify/functions/valid-promo-code", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            code: state.promoCode.trim(),
          }),
        });

        const data = await response.json();

        setState({
          ...state,
          promoCodeInvalid: !data.valid,
          selectedPromoCode: data.code,
          subscriptionType: data.valid
            ? data.code.subscription_type
              ? data.code.subscription_type.toString()
              : state.subscriptionType
            : state.subscriptionType,
          isCheckingPromoCode: false,
          error: data.valid ? null : { message: "Invalid promo code" },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const priceInfo = {
    line1: `$${PRICING.MONTHLY} USD monthly`,
    line2: "monthly",
    line3: "1",
    line4: `$${PRICING.MONTHLY}/mo`,
    line5: "standard",
  };

  if (state.subscriptionType === "9") {
    priceInfo.line1 = `$${PRICING.TRI_MONTHLY} USD 3 month`;
    priceInfo.line2 = "in 3 months";
    priceInfo.line3 = "3";
    priceInfo.line4 = `$${PRICING.TRI_MONTHLY}/3mo`;
    priceInfo.line5 =
      state.selectedPromoCode &&
      state.selectedPromoCode.discount > 0 &&
      state.selectedPromoCode.subscription_type === 1
        ? "discounted"
        : "standard";
  } else if (state.subscriptionType === "10") {
    priceInfo.line1 = `$${PRICING.SEMI_ANNUALLY} USD 6 month`;
    priceInfo.line2 = "in 6 months";
    priceInfo.line3 = "6";
    priceInfo.line4 = `$${PRICING.SEMI_ANNUALLY}/6mo`;
    priceInfo.line5 = "standard";
  }

  const today = new Date();
  const chargeDate =
    state.promoCodeInvalid === false && state.selectedPromoCode !== null
      ? addDays(today, state.selectedPromoCode.num_days)
      : today;

  let promoCodeBoxMessage = "";
  let promoCodeBoxMessage2 = "";
  if (state.selectedPromoCode && state.selectedPromoCode.num_days !== 0) {
    const chargeAmount = moneyCalculation(PRICES[state.subscriptionType], 0);
    promoCodeBoxMessage = `Pay nothing today and cancel your trial anytime if #BALANCE isn't a good fit for you!`;
    promoCodeBoxMessage2 = `Your trial ends on ${format(
      chargeDate,
      "MM/dd/yyyy"
    )}. After that you'll pay $${chargeAmount}. But don't sweat (yet)! Memberships can be canceled any time on the account page!`;
  } else if (state.selectedPromoCode && state.selectedPromoCode.discount > 0) {
    const chargeAmount = moneyCalculation(
      PRICES[state.subscriptionType],
      state.selectedPromoCode.discount
    );
    const term = priceInfo.line4.substring(
      priceInfo.line4.lastIndexOf("/") + 1
    );
    priceInfo.line4 = `$${chargeAmount}/${term}`;
    priceInfo.line6 = ` After the first billing period, price will revert to $${
      PRICES[state.subscriptionType]
    }/${term}.`;
    promoCodeBoxMessage = `Applied! ${state.selectedPromoCode.discount}% discount to be applied, you will be charged $${chargeAmount}`;
  }

  return (
    <div className="signup-v2-wrapper">
      <div className="desktop-header">
        <h2>Sign Up</h2>
      </div>
      <div className="container">
        <div className="spacer" />
        <div className="content">
          <div>
            <div className="form-content">
              <div
                className={classnames({
                  tab: true,
                  active: state.step === STEPS.NAME_EMAIL,
                })}
              >
                1. Name &amp; Email
              </div>
              <div
                className={classnames({
                  tab: true,
                  active: state.step === STEPS.PAYMENT,
                })}
              >
                2. Payment Info
              </div>
            </div>
            {state.step === STEPS.NAME_EMAIL && (
              <div style={{ minWidth: 320 }}>
                <div id="signup-form">
                  <div className="new-signup-form">
                    <div className="top-row">
                      <div className="form-column">
                        <ul className="form-wrapper">
                          <li className="form-row">
                            <label htmlFor="username">
                              <strong>Full Name * </strong>
                            </label>
                            <input
                              name="username"
                              value={state.username}
                              onChange={handleOnChange}
                              placeholder="Jane Smith"
                              type="text"
                            />
                          </li>
                          <li className="form-row">
                            <label htmlFor="email">
                              <strong>E-mail Address * </strong>
                              {state.email && state.emailInvalid && (
                                <p className="field-error">Email is invalid.</p>
                              )}
                            </label>
                            <input
                              name="email"
                              id="email"
                              value={state.email}
                              onChange={handleOnChange}
                              placeholder="jsmith@gmail.com"
                              type="text"
                            />
                          </li>
                          <li className="form-row">
                            <label htmlFor="passwordOne">
                              <strong>Password * </strong>
                              {state.passwordOne.length < 8 &&
                                state.passwordOne !== "" && (
                                  <p className="field-error">
                                    Password must be at least 8 characters.
                                  </p>
                                )}
                              <p className="meta">Minimum 8 characters.</p>
                            </label>

                            <input
                              name="passwordOne"
                              id="passwordOne"
                              value={state.passwordOne}
                              onChange={handleOnChange}
                              type="password"
                            />
                          </li>
                          <li className="form-row">
                            <label htmlFor="passwordTwo">
                              <strong>Confirm Password * </strong>
                              {passwordsDoNotMatch &&
                                state.passwordOne !== "" &&
                                state.passwordTwo !== "" && (
                                  <p className="field-error">
                                    Passwords do not match.
                                  </p>
                                )}
                            </label>

                            <input
                              name="passwordTwo"
                              id="passwordTwo"
                              value={state.passwordTwo}
                              onChange={handleOnChange}
                              type="password"
                            />
                          </li>
                          <li className="form-row">
                            <button
                              className="fancy sub-now-button"
                              disabled={nameEmailInvalid || state.loading}
                              style={{
                                height: "auto",
                                backgroundColor: nameEmailInvalid
                                  ? "#535C5C"
                                  : "#CD4F5F",
                                color: "black",
                              }}
                              onClick={handleNameEmailSubmit}
                              type="button"
                            >
                              {state.loading
                                ? "Saving..."
                                : nameEmailInvalid
                                ? "Fill form above"
                                : "Continue >>"}
                            </button>
                          </li>

                          {!!state.error && (
                            <li className="form-row">
                              <p className="field-error overall-error">
                                {state.error.message}
                              </p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {state.step === STEPS.PAYMENT && (
              <div style={{ minWidth: 320 }}>
                <div id="signup-form">
                  <div className="new-signup-form">
                    <div className="top-row">
                      <div className="form-column">
                        <ul className="form-wrapper">
                          <li className="form-row">
                            <label htmlFor="subscriptionType">
                              <strong>Subscription Plan * </strong>
                              <div>
                                <input
                                  name="subscriptionType"
                                  id="subscriptionType_1"
                                  value={`8`}
                                  checked={state.subscriptionType === `8`}
                                  defaultChecked={
                                    state.subscriptionType === `8`
                                  }
                                  onChange={handleOnChange}
                                  type="radio"
                                />{" "}
                                <label htmlFor="subscriptionType_1">
                                  1 Month ${PRICING.MONTHLY}
                                </label>
                              </div>
                              <div>
                                <input
                                  name="subscriptionType"
                                  id="subscriptionType_2"
                                  value={`9`}
                                  checked={state.subscriptionType === `9`}
                                  defaultChecked={
                                    state.subscriptionType === `9`
                                  }
                                  onChange={handleOnChange}
                                  type="radio"
                                />{" "}
                                <label htmlFor="subscriptionType_2">
                                  3 Months ${PRICING.TRI_MONTHLY}
                                </label>
                              </div>
                              <input
                                name="subscriptionType"
                                id="subscriptionType_3"
                                value={`10`}
                                checked={state.subscriptionType === `10`}
                                defaultChecked={state.subscriptionType === `10`}
                                onChange={handleOnChange}
                                type="radio"
                              />{" "}
                              <label htmlFor="subscriptionType_3">
                                6 Months ${PRICING.SEMI_ANNUALLY}
                              </label>
                            </label>
                          </li>
                          <li className="form-row">
                            <label htmlFor="promoCode">
                              <strong>Promo Code </strong>
                              {state.promoCodeInvalid === true && (
                                <p className="field-error">
                                  Invalid promo code.
                                </p>
                              )}
                            </label>
                            {state.promoCodeInvalid === false &&
                              state.selectedPromoCode !== null && (
                                <li
                                  className="form-row"
                                  style={{ padding: 0, borderRadius: 5 }}
                                >
                                  <label
                                    style={{
                                      backgroundColor: "#00000048",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      textAlign: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <strong>{promoCodeBoxMessage}</strong>
                                      {promoCodeBoxMessage2 !== "" && (
                                        <span className="meta">
                                          {promoCodeBoxMessage2}
                                        </span>
                                      )}
                                    </div>
                                  </label>
                                </li>
                              )}

                            <input
                              name="promoCode"
                              id="promoCode"
                              value={state.promoCode}
                              onChange={handleOnChange}
                              type="text"
                            />
                            {state.promoCodeInvalid === null && (
                              <button
                                className="fancy sub-now-button"
                                disabled={
                                  state.isCheckingPromoCode ||
                                  state.loading ||
                                  state.promoCode.length === 0
                                }
                                onClick={checkPromoCode}
                                type="button"
                                style={{
                                  height: "auto",
                                  backgroundColor:
                                    state.isCheckingPromoCode ||
                                    state.loading ||
                                    state.promoCode.length === 0
                                      ? "#535C5C"
                                      : "#CD4F5F",
                                  color: "black",
                                }}
                              >
                                {state.isCheckingPromoCode
                                  ? "Checking code..."
                                  : "Apply Promo"}
                              </button>
                            )}
                            {state.promoCodeInvalid !== null && (
                              <button
                                className="fancy sub-now-button"
                                disabled={state.loading}
                                type="button"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    selectedPromoCode: null,
                                    promoCodeInvalid: null,
                                    promoCode: "",
                                  });
                                }}
                                style={{
                                  height: "auto",
                                  backgroundColor: state.loading
                                    ? "#535C5C"
                                    : "#CD4F5F",
                                  color: "black",
                                }}
                              >
                                Remove
                              </button>
                            )}
                          </li>{" "}
                          <li className="form-row">
                            <span className="meta">
                              ** <strong>Note:</strong> You will be charged on{" "}
                              {format(chargeDate, "MM/dd/yyyy")} for a{" "}
                              {priceInfo.line3} month subscription at the{" "}
                              {priceInfo.line5} rate of {priceInfo.line4} which
                              will renew monthly unless canceled.
                              {priceInfo.line6} **
                            </span>
                          </li>
                          <li className="form-row">
                            <label htmlFor="purchaseBump">
                              <input
                                style={{ marginRight: "5px" }}
                                name="purchaseBump"
                                id="purchaseBump"
                                type="checkbox"
                                checked={state.purchaseBump}
                                onChange={onChangeCheckbox}
                              />

                              <span className="lower">
                                I also want the #Balanced Eating Kick Start Plan
                                for only $24.99!
                              </span>
                            </label>
                          </li>
                          <li className="form-row">
                            <label>
                              <strong>Payment Details * </strong>
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <a href="https://stripe.com" tabIndex="-1">
                                    <StaticImage
                                      src={"../../img/powered_by_stripe.svg"}
                                      alt="Secure checkout powered by Stripe"
                                      style={{ maxWidth: "8rem" }}
                                    />
                                  </a>
                                </div>
                                <div>
                                  <StaticImage
                                    src={"../../img/credit_card_logos.png"}
                                    alt="Visa, MasterCard, Discover and American Express are accepted"
                                    style={{
                                      maxWidth: "8rem",
                                    }}
                                  />
                                </div>
                              </div>
                              {state.payment && state.payment.error && (
                                <p className="field-error">
                                  {state.payment.error.message}
                                </p>
                              )}
                            </label>
                            <div className="checkout">
                              <CardElement
                                name="payment"
                                onChange={onPaymentChange}
                                {...createOptions(fontSize)}
                              />
                            </div>
                          </li>
                          <li className="form-row">
                            <label htmlFor="agreeTerms">
                              <input
                                style={{ marginRight: "5px" }}
                                name="agreeTerms"
                                id="agreeTerms"
                                type="checkbox"
                                checked={state.agreeTerms}
                                onChange={onChangeCheckbox}
                              />

                              <span className="lower">
                                I have read and agree to the{" "}
                                <Link
                                  className="black-link"
                                  to={ROUTES.TERMS}
                                  state={{ modal: true, noScroll: true }}
                                >
                                  Terms of Use / Privacy Policy
                                </Link>{" "}
                                *
                              </span>
                            </label>
                          </li>
                          <li className="form-row">
                            <label htmlFor="agreeWaiver">
                              <input
                                style={{
                                  marginRight: "5px",
                                  textTransform: "unset",
                                }}
                                name="agreeWaiver"
                                id="agreeWaiver"
                                type="checkbox"
                                checked={state.agreeWaiver}
                                onChange={onChangeCheckbox}
                              />
                              <span className="lower">
                                I have read and agree to the{" "}
                                <Link
                                  className="black-link"
                                  to={ROUTES.WAIVER}
                                  state={{ modal: true, noScroll: true }}
                                >
                                  Informed Consent for Participation in Exercise
                                  & Wellness Activities
                                </Link>{" "}
                                *
                              </span>
                            </label>
                          </li>
                          {!!state.error && (
                            <li className="form-row">
                              <p className="field-error overall-error">
                                {state.error.message}
                              </p>
                            </li>
                          )}
                          <li className="form-row">
                            {/* <Reaptcha
                              sitekey="6LelNb4gAAAAALnlR4wyNfKtr9FszqzQH5e2T021"
                              onVerify={() =>
                                setState({ ...state, verified: true })
                              }
                            /> */}
                            <button
                              className="fancy sub-now-button"
                              disabled={
                                paymentInvalid || state.loading
                                // ||                                state.verified === false
                              }
                              style={{
                                height: "auto",
                                backgroundColor: paymentInvalid
                                  ? "#535C5C"
                                  : "#CD4F5F",
                                color: "black",
                              }}
                              onClick={handlePaymentSubmit}
                              type="button"
                            >
                              {state.loading ? "Saving..." : `Sign Up!`}
                            </button>
                          </li>
                          <li className="form-row">
                            <p className="meta">* Denotes required field</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="what-youll-get">
            <h1>What You Get!</h1>
            <ul>
              <li>
                5 new 30-minute workouts each week, professionally programmed to
                get you results! It's like having an Exercise Physiologist tell
                you what to do every day! <strong>($600 value)</strong>
              </li>
              <li>
                A FREE 1:1 consult with ME (Kristie) to help you get started and
                answer all of your questions! <strong>($45 value)</strong>
              </li>
              <li>
                My 6-Week kick start "Back to BaSIX" program to help you build
                new healthy habits to support your #BALANCED lifestyle{" "}
                <strong>($49 value)</strong>
              </li>
              <li>
                My workout video library with over 50 FULL WORKOUT videos that
                you can access anytime! <strong>($249 value)</strong>
              </li>
              <li>
                Unlimited balanced lifestyle guidance and the support of a
                community of women just like you! <strong>(PRICELESS!)</strong>
              </li>
            </ul>
            <div className="total-value">
              <h3>$943 value!!!</h3>
            </div>
          </div>
        </div>
        <div className="spacer" />
      </div>
    </div>
  );
}

const SignUpForm = compose(withFirebase, injectStripe)(BaseSignUpForm);
// eslint-disable-next-line
export default (props) => {
  // Cheat way of using this hook,
  // Should create HOC to use with compose
  const fbq = useFbq();

  return (
    <StripeWrapper>
      <Elements
        fonts={[
          {
            cssSrc:
              "https://fonts.googleapis.com/css?family=Poppins:300,300i,400,500,600)",
          },
        ]}
      >
        <SignUpForm {...props} fbq={fbq} />
      </Elements>
    </StripeWrapper>
  );
};
